import React, { useEffect, useState } from "react";
import { Input } from "../../../../../../../src/_metronic/_partials/controls";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/settingconfig/settingconfigActions";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Switch } from "@material-ui/core";

const Setting = {
  _id: undefined,
  refPercent: 25,
  ratio: 1000,
  fbLike: 10,
  fbShare: 20,
  twShare: 20,
  appDownload: 100,
  isMaintenance: false,
};
const GeneralConfigEditSchema = Yup.object().shape({
  refPercent: Yup.number()
    .min(1, "Minimum 1 ")
    .max(100, "Maximum 100")
    .required("App Name is required"),
  ratio: Yup.number()
    .min(100, "Minimum 100")
    .max(100000, "Maximum 100000")
    .required("Moto is required"),
  fbLike: Yup.number()
    .min(1, "Minimum 1")
    .max(100000, "Maximum 100000")
    .required("Facebook like reward is required"),
  fbShare: Yup.number()
    .min(1, "Minimum 1")
    .max(100000, "Maximum 100000")
    .required("Facebook Share reward is required"),
  twShare: Yup.number()
    .min(1, "Minimum 1")
    .max(100000, "Maximum 100000")
    .required("Twitter share reward is required"),
  appDownload: Yup.number()
    .min(1, "Minimum 1")
    .max(100000, "Maximum 100000")
    .required("Download app reward is required"),
  isMaintenance: Yup.boolean().required("please check the maintenance property"),
});
function GeneralConfigEditForm({ name }) {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { actionsLoading, settingconfigForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.settingconfig.actionsLoading,
      settingconfigForEdit: state.settingconfig.settingconfigForEdit,
    }),
    shallowEqual
  );
  const [check, setChecked] = useState(false);

  useEffect(() => {
    if (settingconfigForEdit)
    settingconfigForEdit.isMaintenance === true ? setChecked(true) : setChecked(false);
  }, [settingconfigForEdit]);

  useEffect(() => {
    // server call for getting Network by id
    dispatch(actions.fetchSettingconfig(user._id, name));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, dispatch]);
  const saveSetting = (settingconfig) => {
    if (!settingconfig._id) {
      settingconfig.adminId = user._id;
      settingconfig.name = name;
      // server request for creating network
      dispatch(actions.createSettingconfig(settingconfig)).then();
    } else {
      // server request for updating network
      dispatch(actions.updateSettingconfig(settingconfig)).then();
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={settingconfigForEdit || Setting}
      validationSchema={GeneralConfigEditSchema}
      onSubmit={(values) => {
        saveSetting(values);
      }}
    >
      {({ handleSubmit, setFieldValue }) => (
        <>
          <Form className="form form-label-right ">
            <div className="form-group row">
              {/* Product Name */}
              <div className="col-lg-5">
                <Field
                  name="refPercent"
                  component={Input}
                  placeholder="Referral percentage"
                  label="Referral percentage"
                  type="number"
                />
              </div>
              {/* Moto Name */}
              <div className="col-lg-5">
                <Field
                  name="ratio"
                  component={Input}
                  placeholder="Payout Ratio"
                  label="Payout Ratio"
                  type="number"
                />
              </div>
            </div>
            <>
              <div className="form-group row">
                {/* Product Name */}
                <div className="col-lg-5">
                  <Field
                    name="fbShare"
                    component={Input}
                    placeholder="Facebook Share reward"
                    label="Facebook Share reward"
                    type="number"
                  />
                </div>
                {/* Moto Name */}
                <div className="col-lg-5">
                  <Field
                    name="fbLike"
                    component={Input}
                    placeholder="Facebook Like reward"
                    label="Facebook Like reward"
                    type="number"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-5">
                  <Field
                    name="twShare"
                    component={Input}
                    placeholder="Twitter share reward"
                    label="Twitter share reward"
                    type="number"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-5">
                  <Field
                    name="appDownload"
                    component={Input}
                    placeholder="App Download reward"
                    label="App Download reward"
                    type="number"
                  />
                </div>
              </div>
              <div className="form-group row">
                  <div className="col-lg-12">
                    <label style={{ padding: "10px" }} htmlFor="switch">
                      Enable maintenance mode:
                    </label>
                    <Switch
                      checked={check}
                      onChange={(e) => {
                        setChecked(e.target.checked);
                        setFieldValue("isMaintenance", e.target.checked);
                      }}
                      value="check"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </div>
                </div>
            </>
          </Form>
          <div className="form-group row">
            {/* Endpoint Name */}
            <div className="col-lg-6">
              <button
                type="button"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
              {actionsLoading && (
                <div
                  style={{ float: "right" }}
                  className="overlay-layer bg-transparent"
                >
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </Formik>
  );
}

export default GeneralConfigEditForm;
